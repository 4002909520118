import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MemberManagement from '../components/MemberManagement/MemberManagement';
import NotificationManagement from '../components/NotificationManagemant/NotificationManagement';
import SchedulingManagement from '../components/SchedulingManagemant/SchedulingManagement';
import StoreManagement from '../components/StoreManagement/StoreManagement';
import Login from '../components/Login/Login';
// import SignUp from '../components/Sign/SignUp';

function Main() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} /> 
        <Route path="/member" element={<MemberManagement />} />
        <Route path="/notification" element={<NotificationManagement />} />
        <Route path="/scheduling" element={<SchedulingManagement />} />
        <Route path="/store" element={<StoreManagement />} />
        {/* <Route path="/signup" element={<SignUp />} />  */}
      </Routes>
    </div>
  );
}

export default Main;






// import React from 'react';
// import { Routes, Route } from 'react-router-dom';
// import MemberManagement from '../components/MemberManagement/MemberManagement';
// import NotificationManagement from '../components/NotificationManagemant/NotificationManagement';
// import SchedulingManagement from '../components/SchedulingManagemant/SchedulingManagement';
// import StoreManagement from '../components/StoreManagement/StoreManagement';
// import Login from '../components/Login/Login';
// import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute'; 

// function Main() {
//   return (
//     <div>
//       <Routes>
//         <Route path="/" element={<Login />} />
        
//         <Route
//           path="/member"
//           element={
//             <ProtectedRoute>
//               <MemberManagement />
//             </ProtectedRoute>
//           }
//         />
//         <Route
//           path="/notification"
//           element={
//             <ProtectedRoute>
//               <NotificationManagement />
//             </ProtectedRoute>
//           }
//         />
//         <Route
//           path="/scheduling"
//           element={
//             <ProtectedRoute>
//               <SchedulingManagement />
//             </ProtectedRoute>
//           }
//         />
//         <Route
//           path="/store"
//           element={
//             <ProtectedRoute>
//               <StoreManagement />
//             </ProtectedRoute>
//           }
//         />
//       </Routes>
//     </div>
//   );
// }

// export default Main;
