import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; 
import "./TopNavbar.css";
import avatarIcon from "../../assets/Walnut/avator.png";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import NotificationsSharpIcon from "@mui/icons-material/NotificationsSharp";

const TopNavbar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate(); 

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    setShowDropdown(false);
    localStorage.removeItem("authToken");

    localStorage.setItem("logoutEvent", Date.now()); 
    navigate("/"); 
  };

  return (
    <div className="top-nav">
      {/* <input
        type="text"
        name="search"
        placeholder="Search Walnut Golf"
        className="search-bar"
      /> */}

      <div className="user-info">
        <NotificationsSharpIcon sx={{ color: "gray", marginRight: "25px" }} />
        <div className="vl"></div>

        <img
          src={avatarIcon}
          alt="Avatar"
          className="avatar"
        />

        <button
          className="dropdown-btn"
          onClick={toggleDropdown}
          style={{ background: "none", border: "none", cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          <KeyboardArrowDownSharpIcon sx={{ color: "gray" }} />
        </button>

        {showDropdown && (
          <div className="dropdown-menu">
            <button onClick={handleLogout}>Logout</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopNavbar;
