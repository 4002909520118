import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  TablePagination,
} from "@mui/material";
import "./StoreManagement.css";
import add from "../../assets/Walnut/add.svg";
import EditProductModal from "./EditProductModal";
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import TopNavbar from "../TopNavbar/TopNavbar";
import api from "../../api/tokenCode";

const StoreManagement = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [needsRefresh, setNeedsRefresh] = useState(false); 

  const fetchProducts = async () => {
    try {
      const response = await api.get("/admin/getProducts", {
        params: {
          search: searchTerm,
          limit: rowsPerPage,
          skip: page * rowsPerPage,
        },
      });
      const productList = response.data.list || [];
      setProducts(productList);
      setTotalRows(response.data.count);
    } catch (error) {
      setProducts([]);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (needsRefresh) {
      fetchProducts(); 
      setNeedsRefresh(false); 
    }
  }, [needsRefresh]);

  useEffect(() => {
    fetchProducts(); 
  }, [page, rowsPerPage, searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(0);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setNeedsRefresh(true); 
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tableHeaderCellStyle = {
    color: "#000",
    fontWeight: "bold",
    padding: "20px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#F6F8FA",
    textAlign: "center",
    fontSize: "18px",
  };

  const tableCellStyle = {
    padding: "10px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    textAlign: "center",
    fontSize: "15px",
  };

  return (
    <Box sx={{ marginLeft: 5 }}>
      <LeftSidenav />
      <TopNavbar />
      <Box className="store-padding-container">
        <TableContainer component={Paper} sx={{ backgroundColor: "#F6F8FA" }}>
          <Table>
            <div className="store-header">
              <h2>Store Management</h2>
              <div style={{ paddingRight: "15px" }}>
                <Button
                  className="add-btn"
                  sx={{
                    color: "white",
                    backgroundColor: "Black",
                    gap: "10px",
                    border: "1px solid #ccc",
                    height: "40px",
                    width: "125px",
                    padding: "8px",
                  }}
                  onClick={handleOpenModal}
                >
                  Add New
                  <img src={add} alt="add" />
                </Button>
              </div>
            </div>
          </Table>
          <hr />

          <Table className="store-table">
            <div style={{ paddingLeft: "20px" }}>
              <div className="store-filters">
                <input
                  name="search"
                  className="store-search-text"
                  placeholder="Search by Product name"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </Table>

          <Table
            sx={{
              minWidth: 650,
              borderCollapse: "separate",
              borderSpacing: "0 20px",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeaderCellStyle}>Product Image</TableCell>
                <TableCell sx={tableHeaderCellStyle}>Product Name</TableCell>
                <TableCell sx={tableHeaderCellStyle}>Price</TableCell>
                {/* <TableCell sx={tableHeaderCellStyle}>Stock Status</TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {products.length > 0 ? (
                products.map((product) => (
                  <TableRow key={product._id}>
                    <TableCell sx={tableCellStyle}>
                      <img
                        src={product.productPicter}
                        alt={product.productName}
                        style={{ width: "50px" }}
                      />
                    </TableCell>
                    <TableCell sx={tableCellStyle} className="capitalize-product">
                      {product.productName}
                    </TableCell>
                    <TableCell sx={tableCellStyle}>
                      {product.price}
                    </TableCell>
                    {/* <TableCell sx={tableCellStyle}>
                      <span
                        className={
                          product.stockStatus.toLowerCase() === "in stock"
                            ? "in-stock"
                            : "out-of-stock"
                        }
                      >
                        {product.stockStatus}
                      </span>
                    </TableCell> */}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    sx={{
                      textAlign: "center",
                      padding: "20px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 60, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <EditProductModal open={openModal} handleClose={handleCloseModal} />
      </Box>
    </Box>
  );
};

export default StoreManagement;
