import axios from 'axios';
import { BASE_URL } from '../Constant/url';

const api = axios.create({
  baseURL: `${BASE_URL}`,
});

const accessToken = localStorage.getItem('access_token');

export const setAuthToken = (token) => {
  if (token) {
    console.log("Access Token:", token);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

if (accessToken) {
  setAuthToken(accessToken);
}

export default api;
