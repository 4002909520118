import React, { useEffect } from "react";
import './App.css';
import Main from './Routes/Routes';  
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function LogoutListener() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogoutEvent = (event) => {
      if (event.key === "logoutEvent") {
        navigate("/");
      }
    };

    window.addEventListener("storage", handleLogoutEvent);

    return () => {
      window.removeEventListener("storage", handleLogoutEvent);
    };
  }, [navigate]);

  return null; 
}

function App() {
  return (
    <Router>
      <LogoutListener />
      <Main />        
    </Router>
  );
}

export default App;
