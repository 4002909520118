import React from "react";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import expotarrow from "../../assets/Walnut/exportarrow.svg";


const ExportButton = () => {
  const handleExportClick = async () => {
    const element = document.getElementById("export"); 
    if (element) {
      try {
        const canvas = await html2canvas(element, {
          scale: 2, 
          useCORS: true, 
        });
        const image = canvas.toDataURL("image/png");

        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(image);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.setTextColor(36, 82, 123); 
        
        pdf.setFontSize(18);
        
        

        pdf.setFont("helvetica", "bold"); 
        

        const xOffset = 1; 
        const imageYPosition = 20; 

        pdf.addImage(image, "PNG", xOffset, imageYPosition, pdfWidth - xOffset, pdfHeight);

        // pdf.addImage(image, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("scheduleDate.pdf");
      } catch (error) {
        console.error("An error occurred while exporting the PDF:", error) 
      }
    } else {  
      console.error("Element with id 'export' not found");
    }
  };

  return (
    
      <img src={expotarrow} alt="expotarrow"
      onClick={handleExportClick} style={{ marginLeft: '10px', background: 'none', border: 'none', cursor: 'pointer',borderRadius: "50%" }}
      />
  );
};

export default ExportButton;



