import React, { useState, useEffect } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import signupphoto from "../../assets/Walnut/wall-nut.png";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import api from "../../api/tokenCode";
import { ToastContainer, toast } from 'react-toastify'; 

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");
    if (savedEmail && savedPassword) {
      setFormData({
        email: savedEmail,
        password: savedPassword,
      });
      setRememberMe(true); 
    }
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.password) {
      setError("Please enter both email and password.");
      return;
    }

  try {
    const response = await api.post("/user/login", formData);
    console.log(response.data.role,"test");

    if (response.data && response.data.role === 'A') {
      localStorage.setItem('userRole', 'admin');  
      localStorage.setItem("authToken", response.data.token);
  
      if (rememberMe) {
        localStorage.setItem("email", formData.email);
        localStorage.setItem("password", formData.password);
      } else {
        localStorage.removeItem("email");
        localStorage.removeItem("password");
      }

      // Success toast message
      toast.success('Login successful! Welcome to the admin site.');

      // Redirect to the member page after successful login
      setTimeout(() => {
        navigate("/member");
      }, 2000);
    } else {
      toast.error('You are not authorized to access the admin site.');
    }

    if (response.status !== 200) {
      throw new Error("Invalid email or password.");
    }

  } catch (err) {
    setError(
      err.response?.data?.message ||
        "Login failed, please check your credentials."
    );
  }
};

const handleRememberMeChange = (e) => {
  setRememberMe(e.target.checked);
};

  return (
    <section className="loginpage-sec">
       <ToastContainer />
      <div className="signup-container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="signup-image">
              <img
                src={signupphoto}
                alt="signup-photo"
                className="login-image"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="signup-form">
              <form onSubmit={handleSubmit}>
                <h1>Get Started!</h1>
                <p>Log in to Your Golf Adventure!</p>

                {error && <p className="error">{error}</p>}
                <div className="input-field">
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter email address"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="input-field">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Enter password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    className="eye-open"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </div>

                <div className="options">
                  <label>
                    <input
                      type="checkbox"
                      className="remember-checkbox"
                      checked={rememberMe}
                      onChange={handleRememberMeChange}
                    />
                    Remember Me
                  </label>
                </div>

                <button type="submit" className="login-button">
                  Login
                </button>
              </form>

              {/* <p className="signup-1-link">
            Don’t have an account? <Link to="/signup">Sign Up</Link>
          </p> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
